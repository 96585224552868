<template>
  <div class="notification" :class="type">
    <span>{{ message }}</span>
    <XIcon class="notification-close" @click="onClosePress" />
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
export default {
  name: "Notification",
  components: { XIcon },
  props: {
    message: String,
    type: String,
  },
  emits: ["onClose"],
  methods: {
    onClosePress() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped lang="scss">
</style>
