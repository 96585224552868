// Correct module definition in files.js
import axios from '../../model/axios'; // Adjust the path as necessary

const files = {
  namespaced: true,
  
  actions: {
    uploadFile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const file = payload.file;
        const allowedTypes = process.env.VUE_APP_FILE_ALLOWED_TYPES.split(',');
        const maxSize = parseInt(process.env.VUE_APP_FILE_MAX_SIZE_MB, 10) * 1024 * 1024; // 15 MB

        if (!allowedTypes.includes(file.type)) {
          reject(new Error('Invalid file type. Only PDF, Word, Excel, and Open Document formats are allowed.'));
          return;
        }

        if (file.size > maxSize) {
          reject(new Error('File size exceeds the 15 MB limit.'));
          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('File uploaded successfully', response);
          resolve(response);
        })
        .catch(error => {
          console.error('Error uploading file', error);
          reject(error);
        });
      });
    }
  }
};

export default files;