<template>
  <div class="message-group" v-bind:class="{ right: !group.system }">
    <div class="group-messages">
      <div class="group-messages--inner">
        <transition-group name="list">
          <template v-for="message in messages" :key="message.uuid">
            <simple-message
              v-if="message.messageType === MessageType.Text"
              :message="message"
              class="list-item"
            />
            <video-message
              v-if="message.messageType === MessageType.Video"
              :message="message"
            />
            <image-message
              v-if="message.messageType === MessageType.Image"
              :message="message"
            />
            <option-message
              v-if="message.messageType === MessageType.Option"
              :message="message"
              :showOptions="group === lastGroup"
            />
          </template>
        </transition-group>

        <div v-if="isTyping" class="typing-indicator">
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/model/eventBus";
import { mapGetters } from "vuex";
import { MessageType } from "../../model/message-types";

import SimpleMessage from "./SimpleMessage.vue";
import OptionMessage from "./OptionMessage.vue";
import VideoMessage from "./VideoMessage.vue";
import ImageMessage from "./ImageMessage.vue";

export default {
  components: { SimpleMessage, OptionMessage, VideoMessage, ImageMessage },
  name: "MessageGroup",
  props: {
    group: Object,
  },
  data() {
    return { messages: [], toRender: [], isTyping: false };
  },
  created() {
    this.MessageType = MessageType;
  },
  computed: {
    ...mapGetters({
      lastGroup: "Messages/getLastMessage",
    }),
  },

  watch: {
    group: {
      handler() {
        if (!this.group.system) return;
        this.toRender = this.group.messages.filter(
          (x) => !this.messages.includes(x)
        );
        this.renderNextMessage();
      },
      deep: true,
      immediate: true,
    },
  },

  updated() {
    eventBus.$emit("scrollDownPlease");
  },

  mounted() {
    if (!this.group.system) {
      this.isTyping = false;
      this.messages = this.group.messages;
      return;
    }
  },

  methods: {
    renderNextMessage() {
      if (this.toRender.length <= 0 || this.isTyping == true) return;
      if (this.group !== this.lastGroup) {
        this.messages = this.group.messages;
        return;
      }
      this.isTyping = true;
      setTimeout(() => {
        this.messages.push(this.toRender.shift());
        this.isTyping = false;
        setTimeout(() => {
          this.renderNextMessage();
        }, 500);
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
