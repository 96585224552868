import store from "../store/index";
import axios from "@/model/axios";
import { ConnectionState } from "../store/modules/global";
import storage from "./storage-messages";

console.log(storage);

let ws = undefined;

function createConnection() {

    if (ws !== undefined) {
        ws.onclose = () => { };
        ws.close();
        ws = null;
    }

    ws = new WebSocket(process.env.VUE_APP_SOCKET_URL);
    store.dispatch("Global/setConnectionState", ConnectionState.WebSocketConnecting);

    ws.onopen = () => {
        console.info("Connection established");
        store.dispatch("Global/setConnectionState", ConnectionState.Connected);
    }

    ws.onerror = () => {
        console.error("Connection Error");
        store.dispatch("Global/setConnectionState", ConnectionState.Disconnected);
    }

    ws.onclose = () => {
        console.warn("Connection Closed");
        console.log("Trying to reconnect");
        store.dispatch("Global/setConnectionState", ConnectionState.Disconnected);
        setTimeout(() => {
            connect();
        }, 1000)
    }

    ws.onmessage = event => {
        const oResp = JSON.parse(event.data);
        if (oResp.error) {
            store.dispatch("Global/addNotification", { type: 'error', message: oResp.error?.message });
            return;
        }
        if (oResp.system === undefined) oResp.system = true;
        store.dispatch("Messages/receiveMessage", oResp);
    }
}

function getSession() {
    return axios.get("/ws/create-session", { withCredentials: true });
}

function connect() {
    store.dispatch("Global/setConnectionState", ConnectionState.ResolvingToken);
    getSession().then(createConnection).catch(() => {
        setTimeout(() => {
            connect();
        }, 1000);
    });
}
setTimeout(connect, 100);

export default {
    send: (mMessage) => {
        ws.send(JSON.stringify(mMessage));
    }
}
