import store from "../store/index";
import { ChatMessageGroup } from "./message-types";

const MESSAGE_KEY = `${process.env.VUE_APP_STORAGE_PREFIX}chat-messages`;

// reaguje pouze na změny z jiného okna na stejné doméně
window.addEventListener('storage', (event) => {
    if (event.key === MESSAGE_KEY) store.dispatch("Messages/loadStorageData");
});

function getMessages() {
    const rawStringData = window.localStorage.getItem(MESSAGE_KEY);
    if (rawStringData === null || rawStringData === "") return [];
    const rawData = JSON.parse(rawStringData),
        data = [];

    rawData.forEach(dataGroup => {
        const messageGroup = new ChatMessageGroup();
        messageGroup.assignData(dataGroup);
        data.push(messageGroup);
    });

    return data;
}

function saveMessages(data) {
    window.localStorage.setItem(MESSAGE_KEY, JSON.stringify(data));
}

export default {
    getMessages,
    saveMessages
}