<template>
  <div class="message">
    <div class="fullscreen">
      <iframe width="100%" height="100%"
        :src=checkYoutubeURL(message.source)
        webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
      </iframe>
    </div>
  </div>
</template>

<script>
import { ChatVideoMessage } from '@/model/message-types';

export default {
  name: "VideoMessageItem",
  props: {
    message: ChatVideoMessage,

  },
    components: {
  },
  methods: {
    checkYoutubeURL(link) {
      //if(!link.includes("?rel=0&autoplay=1")){
      //  link = link + "?rel=0&autoplay=1";
      //}
      return link.replace("watch?v=", "embed/");
    }
  }
};
</script>

<style scoped lang="scss">
</style>
