<template>
  <div class="notifications" v-show="notifications.length > 0">
    <notification
      v-for="notification in notifications"
      v-bind:key="notification"
      :type="notification.type"
      :message="notification.message"
      @onClose="removeNotification(notification)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Notification from "./Notification.vue";

export default {
  components: { Notification },
  name: "NotificationGroup",
  computed: {
    ...mapGetters({
      notifications: "Global/getNotifications",
    }),
  },
  methods: {
    removeNotification(notification) {
      this.$store.dispatch("Global/removeNotification", notification);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
