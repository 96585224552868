<template>
  <div class="chip-item">{{ text }}</div>
</template>

<script>
export default {
  name: "OptionMessageItem",
  props: {
    text: String,
    val: String,
  },
};
</script>

<style scoped lang="scss">
</style>
