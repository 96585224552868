<template>
    <div class="message">
      <div class="image-display">
        <img :src="message.source" alt="Image" width="100%" height="100%" />
      </div>
    </div>
  </template>
  
  <script>
  import { ChatImageMessage } from '@/model/message-types';
  
  export default {
    name: "ImageMessageItem",
    props: {
      message: ChatImageMessage,
    },
    components: {
    },
  };
  </script>
  
  <style scoped lang="scss">
  .image-display {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    object-fit: contain;
  }
  </style>