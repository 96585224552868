import storageMessages from "../../model/storage-messages";
import websocket from "../../model/websocket";

export const ConnectionState = Object.freeze({ Disconnected: 0, ResolvingToken: 1, WebSocketConnecting: 2, Connected: 99 });

const store = {

    namespaced: true,

    state: {
        notifications: [],
        isFullscreen: false,
        isOpened: false,
        connectionState: ConnectionState.ResolvingToken,
    },

    getters: {

        getNotifications: (state) => state.notifications,
        getNotificationsCount: (state) => state.notifications.length,

        isOpened: (state) => state.isOpened,
        isFullscreen: (state) => state.isFullscreen,
        isConnected: (state) => state.connectionState === ConnectionState.Connected,
        getConnectionState: (state) => state.connectionState,
        getConnectionStateMessage: (state) => {
            switch (state.connectionState) {
                case ConnectionState.Disconnected: return "Jsem offline.. x(";
                case ConnectionState.ResolvingToken: return "Přihlašuji..";
                case ConnectionState.WebSocketConnecting: return "Připojuji..";
                case ConnectionState.Connected: return "Připojen! :)";
                default: return "";
            }
        }

    },

    mutations: {

        addNotification(state, mNotification) {
            state.notifications.push(mNotification);
        },

        removeNotification(state, notification) {
            state.notifications = state.notifications.filter(item => item !== notification);
        },

        toggleFullscreen(state) {
            state.isFullscreen = !state.isFullscreen;
        },

        toggleIsOpened(state) {
            state.isOpened = !state.isOpened;
        },

        setConnectionState(state, connectionState) {
            state.connectionState = connectionState;
        }

    },

    actions: {

        toggleIsOpened({ commit }) {
            commit('toggleIsOpened');
            commit("Messages/setUnreadCount", 0, { root: true });
        },

        addNotification({ commit }, mNotification) {
            commit('addNotification', mNotification);
        },

        removeNotification({ commit }, mNotification) {
            commit('removeNotification', mNotification);
        },

        toggleFullscreen({ commit }) {
            commit('toggleFullscreen');
        },

        setConnectionState({ commit }, connectionState) {
            commit('setConnectionState', connectionState);
            if (connectionState === ConnectionState.Connected) {
                const messages = storageMessages.getMessages();
                if (messages.length <= 0) {
                    websocket.send({ message: "" });
                }
            }
        }
    }

};


export default store;