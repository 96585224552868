<template>
  <div class="user-input">
    <input
      ref="input"
      type="text"
      v-model="input"
      v-on:keyup.enter="onEnter"
      @focus="handleFocus"
      :disabled="!enabled"
      :placeholder="placeholderText"
      class="small-font"
    />
    <button @click="onEnter" :disabled="!enabled" class="button--send">
      <PaperAirplaneIcon class="icon--send" />
    </button>
  </div>
</template>

<script>
import eventBus from "@/model/eventBus";
import { PaperAirplaneIcon } from "@heroicons/vue/solid";

export default {
  name: "ChatInput",
  components: { PaperAirplaneIcon },
  props: {
    msg: String,
    enabled: Boolean,
  },

  emits: {
    onMessageSent: ({ message }) => {
      if (message && message.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  data() {
    return {
      input: "",
      enabled: true,
      isTykani: process.env.VUE_APP_TYKANI === 'true'
    };
  },
  computed: {
    placeholderText() {
      return this.isTykani ? 'Napiš mi svůj dotaz ...' : 'Napište mi svůj dotaz ...';
    }
  },
  methods: {
    _clearInput() {
      this.input = "";
    },

    onEnter() {
      this.$emit("onMessageSent", { message: this.input });
      this._clearInput();
    },

    handleFocus() {
      eventBus.$emit("scrollDownPlease");
    },

    focusInput() {
      this.$nextTick(() => {
        if (this.enabled && this.$refs.input) {
          this.$refs.input.focus();
        }
      });
    }
  },
  mounted() {
    this.focusInput();
    eventBus.$on("windowOpened", () => {
      this.focusInput();
    });
  },
};
</script>

<style scoped lang="css">

.small-font {
  font-size: small;
}
.icon--send {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 90deg;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}
</style>
