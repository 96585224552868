<template>
  <div class="chat-bubble">
    <div v-show="showWelcome" class="chat-bubble-welcome">{{ welcome }}</div>

    <div class="chat-bubble-btn">
      <img src="@/assets/images/logo.png" alt="" />
      <div v-show="unread > 0" class="unread-notif">{{ unread }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatBubble",
  props: {
    unread: Number,
    welcome: String,
    showWelcome: Boolean,
  },

  mounted() {},
};
</script>

<style scoped lang="scss">
</style>
