import websocket from "../../model/websocket";
import storage from "../../model/storage-messages";
import { ChatImageMessage, ChatMessageGroup, ChatOptionMessage, ChatOptionMessageItem, ChatTextMessage, ChatVideoMessage, MessageType } from "../../model/message-types";

function addMessage(stateData, mMessage) {
    if (mMessage.system !== true && mMessage.system !== false)
        return;

    const groupExisted = stateData[stateData.length - 1]?.system === mMessage.system;
    const group = groupExisted
        ? stateData[stateData.length - 1]
        : new ChatMessageGroup(mMessage.system === true);

    if (mMessage.messages && mMessage.messages instanceof Array) {
        mMessage.messages.forEach(item => {
            const message = createMessageInstance(item);
            if (message) group.addMessage(message);
        });
    }

    if (mMessage.message) {
        const message = new ChatTextMessage(mMessage.message);
        group.addMessage(message);
    }

    if (!groupExisted) stateData.push(group);
}

function createMessageInstance(mMessage) {
    switch (mMessage.messageType) {
        case MessageType.Text: return new ChatTextMessage(mMessage.text);
        case MessageType.Option: return getOptionMessageInstance(mMessage);
        case MessageType.Suggestion: return getSuggestionMessageInstance(mMessage);
        case MessageType.Video: return new ChatVideoMessage(mMessage.title, mMessage.source, mMessage.description, mMessage.alt);
        case MessageType.Image: return new ChatImageMessage(mMessage.title, mMessage.source, mMessage.description, mMessage.alt);
        default: return null;
    }

    function getOptionMessageInstance(mMessage) {
        const optionMessage = new ChatOptionMessage(mMessage.title, mMessage.description);
        mMessage.options.forEach(option => {
            optionMessage.addOption(new ChatOptionMessageItem(option.label, option.value));
        });
        return optionMessage;
    }

    function getSuggestionMessageInstance(mMessage) {
        const optionMessage = new ChatOptionMessage(mMessage.title, mMessage.description);
        mMessage.suggestions.forEach(option => {
            optionMessage.addOption(new ChatOptionMessageItem(option.label, option.value));
        });
        return optionMessage;
    }
}

const store = {

    namespaced: true,

    state: {
        data: [],
        options: [],
        unreadCount: 0,
        inputVisibility: process.env.VUE_APP_INPUT_VISIBILITY_DEFAULT === 'true',
        fileVisibility:process.env.VUE_APP_FILE_VISIBILITY_DEFAULT === 'true'
    },

    getters: {

        getMessages: (state) => state.data,
        getMessagesCount: (state) => state.data.length,
        getUnreadMessagesCount: (state) => state.unreadCount,
        getLastMessage: (state) => state.data[state.data.length - 1],
        getInputVisibility: (state) => state.inputVisibility,
        getFileVisibility: state => state.fileVisibility,
    },

    mutations: {
        addMessage(state, mMessage) {
            addMessage(state.data, mMessage);
            storage.saveMessages(state.data);
        },
        
        setInputVisibility(state, visibility) {
            state.inputVisibility = visibility;
        },
        
        setFileVisibility(state, visibility) {
            state.fileVisibility = visibility;
        },

        setUnreadCount(state, count) {
            state.unreadCount = count;
        },

        loadStorageData(state, data) {
            state.data = data;
        }

    },

    actions: {

        loadStorageData({ commit }) {
            const messages = storage.getMessages();
            commit('loadStorageData', messages);
            // console.log("Data loaded from storage");
        },

        receiveMessage({ commit, rootGetters, getters }, mMessage) {
            if (!mMessage) return;
            const inputVisibilityTag = process.env.VUE_APP_INPUT_VISIBILITY_TAG;
            const inputVisibilityDefault = process.env.VUE_APP_INPUT_VISIBILITY_DEFAULT === 'true';
            const fileVisibilityTag= process.env.VUE_APP_FILE_VISIBILITY_TAG;
            const fileVisibilityDefault = process.env.VUE_APP_FILE_VISIBILITY_DEFAULT === 'true';
            
            if (mMessage.messages) {
              mMessage.messages.forEach(msg => {
                let setInputVisibility = inputVisibilityDefault;
                let setFileVisibility = fileVisibilityDefault;

                if ((msg.text && msg.text.includes(inputVisibilityTag)) || (msg.title && msg.title.includes(inputVisibilityTag))) {
                  setInputVisibility = !inputVisibilityDefault;
            
                  if (msg.text && msg.text.includes(inputVisibilityTag)) {
                    msg.text = msg.text.replace(inputVisibilityTag, '').trim();
                  }
            
                  if (msg.title && msg.title.includes(inputVisibilityTag)) {
                    msg.title = msg.title.replace(inputVisibilityTag, '').trim();
                  }
                }

                if ((msg.text && msg.text.includes(fileVisibilityTag)) || (msg.title && msg.title.includes(fileVisibilityTag))) {
                    setFileVisibility = !fileVisibilityDefault;
                
                    if (msg.text && msg.text.includes(fileVisibilityTag)) {
                      msg.text = msg.text.replace(fileVisibilityTag, '').trim();
                    }
                
                    if (msg.title && msg.title.includes(fileVisibilityTag)) {
                      msg.title = msg.title.replace(fileVisibilityTag, '').trim();
                    }
                }
                commit('setInputVisibility', setInputVisibility);
                commit('setFileVisibility', setFileVisibility);
              });
            }
            commit('addMessage', mMessage);
            if (!rootGetters["Global/isOpened"]) {
                commit("setUnreadCount", getters.getUnreadMessagesCount + 1);
            }
        },

        addMessage({ commit }, mMessage) {
            commit('addMessage', mMessage);
        },

        sendMessage({ commit }, { message, option }) {
            if (message.trim() == "") return;
            const msg = { message, option, system: false };
            websocket.send(msg);
            commit('addMessage', msg);
        },
        sendLocation ({ commit }, { location }) {
            const sendLocationEnv = process.env.VUE_APP_SEND_LOCATION === 'true';
            if (sendLocationEnv) {
                const locationKey = process.env.VUE_APP_LOCATION_KEY;
                const msg = { message: locationKey + " " + location, system: false };
                websocket.send(msg);
            } else {
                console.log('Sending location is disabled.');
            }
        },
    }

};


export default store;